import styled from "styled-components";
import { BP } from "../../commons/Theme";

export const StyledTrustpilotContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  @media (${BP.ipad}) {
    padding-top: 10px;
    padding-bottom: 20px;
  }
`;
