import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StyledTrustpilotContainer } from "./style";

const Trustpilot = (props) => {
  const { pageImages, languages } = useContext(DataContext);
  const imageData = pageImages.find(e => e.path === props.image);

  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const code = currentLanguageCode === "en" ? "uk" : currentLanguageCode;

  return (
    <StyledTrustpilotContainer onClick={() => {
      window.dataLayer.push({
        "event": "ClickTrustpilot",
      });
    }}>
      <a href={`https://${code}.trustpilot.com/review/www.3bee.it`} target="_blank" rel="noreferrer">
        <GatsbyImage style={{ width: "120px" }} image={getImage(imageData)} alt={imageData.alt} />
      </a>
    </StyledTrustpilotContainer>
  );
};

export default Trustpilot;
